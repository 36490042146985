import React from "react";

import { Spinner as RBSpinner } from 'react-bootstrap';
const Spinner = props => {
    return (
        <div className="container-xxl bg-white p-0">
            <div id="spinner"
                 className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
                <RBSpinner animation="grow" variant="success" />
            </div>
        </div>
    );
}

export default Spinner;