import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleExclamation, faClock} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

const RestHoursList = props => {
    return (
        <div className="row g-4">
            {
                props.restHoursList.map((item,index)=>(
                    <div key={item.restBooking_item_no} className="col-lg-4 col-md-6 fadeInUp">
                        <div className="room-item shadow rounded overflow-hidden">
                            <div className="p-4 mt-2">
                                <div style={{textAlign:"center",color:"rgb(106, 218, 169)"}}>
                                    <FontAwesomeIcon style={{fontSize:"7em"}} icon={faClock} />
                                    <div style={{fontSize:"3em"}}>{item.restBooking_hours}小時</div>
                                </div>
                                <div style={{fontSize:"1.2em",fontWeight:"bold"}}>
                                    <div style={{color:"red"}}>
                                        <FontAwesomeIcon icon={faCircleExclamation} /> 由系統安排房間
                                    </div>
                                    <div style={{textAlign:"right"}}>入住 {item.restBooking_hours} 小時，總共</div>
                                </div>
                                <div style={{textAlign:"right",fontWeight:"bold"}} className={"h3"}>NTD {item.restBooking_price}</div>
                                <div className="d-flex justify-content-between">
                                    <Button
                                        // id={item.room_type_id}
                                        className={"col-5 btn btn-sm rounded detailBtn roomTypeListDetailBtn"}
                                        onClick={(e)=>props.restBooking_detail(item.restBooking_hotel_id,item.restBooking_hours,props.checkInDate,props.checkInTime,item.restBooking_price)}
                                    >詳情</Button>
                                    <Button
                                        // id={item.room_type_id}
                                        className={"col-5 btn btn-sm rounded mainBtn roomTypeListBookingBtn"}
                                        // onClick={booking_search}
                                        onClick={()=> {
                                            props.bookIn(3,item.restBooking_hotel_id,item.restBooking_hours,props.checkInDate,props.checkInTime,item.restBooking_price);
                                        }}
                                    >預訂</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default RestHoursList;