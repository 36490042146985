import React, {useEffect, useRef} from "react";
import { useParams } from 'react-router-dom';
import Spinner from "../Spinner/Spinner";
import axios from "axios";
import Url from "../../Url/Url";

const apiServer = Url;

const Pay = props => {

    const {id} = useParams();
    let payLink_MIDRef = useRef(null);
    let payLink_VersionRef = useRef(null);
    let payLink_TradeInfoRef = useRef(null);
    let payLink_TradeShaRef = useRef(null);

    useEffect(()=>{
        axios.post(`${apiServer}/orders/pay`,{
            itemId1:id
        }).then((data) =>{
            if(data.status === 200){
                if(data.data.length != 0){
                    if(data.data[0].result != 0){
                        payLink_MIDRef.current.value = data.data[0].result.MerchantID;
                        payLink_VersionRef.current.value = data.data[0].result.Version;
                        payLink_TradeInfoRef.current.value = data.data[0].result.TradeInfo;
                        payLink_TradeShaRef.current.value = data.data[0].result.TradeSha;

                        // console.log(data.data);

                        document.getElementById('payLinkForm').submit();
                    }
                }else{
                }
            }else{
                console.log("Server Error")
            }
        });
    },[]);
    return (
        <div>
            <Spinner/>

            <form  id={"payLinkForm"} className={"display_none"} method={"post"} action={"https://ccore.newebpay.com/MPG/mpg_gateway"}>
                <div>
                    MID: <input name="MerchantID" ref={payLink_MIDRef} readOnly />
                    Version: <input name="Version" ref={payLink_VersionRef} readOnly />
                    TradeInfo: <input name="TradeInfo" ref={payLink_TradeInfoRef} readOnly />
                    TradeSha: <input name="TradeSha" ref={payLink_TradeShaRef} readOnly />
                </div>
            </form>
        </div>
    );
}

export default Pay;