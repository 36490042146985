import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/free-solid-svg-icons";
import {Container} from "react-bootstrap";

const Result = props => {
    return (
        <Container fluid style={{maxWidth:"1400px",marginTop:"8em"}}>
            <div style={{textAlign:"center"}} className={"mt-5 mb-5"}>
                <FontAwesomeIcon icon={faCircleCheck} style={{color:"rgb(106, 218, 169)",fontSize:"8em"}} /><br/>
                <span style={{color:"rgb(106, 218, 169)",fontSize:"2em"}}>付款成功！</span>
            </div>
        </Container>
    );
}

export default Result;