import React, {Component} from "react";

const HotelList = props => {
    return (
        <div className="row g-4 mt-3">
            {props.hotel.map((item, index) => (
                <div key={item.hotel_id} className="col-12 fadeInUp mt-3 mb-4">
                    <div className="room-item shadow rounded overflow-hidden" style={{padding: "20px"}}>
                        <div style={{display: "inline-block", width: "250px", height: "170px"}}>
                            <img style={{height: "100%", width: "100%"}} className="img-fluid" src="img/room-1.jpg"
                                 alt=""/>
                        </div>
                        <div style={{display: "inline-block", margin: "20px"}} className={""}>
                            <h3 className="mb-0">{item.hotel_name}</h3>
                            <span>地址：{item.hotel_address}</span><br/>
                            <span>電話：{item.hotel_tel}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default HotelList;