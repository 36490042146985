import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBed, faPerson} from "@fortawesome/free-solid-svg-icons";
import {Button} from "react-bootstrap";

const RoomTypeList = props => {
    return (
        <div className="row g-4">
            {
                props.roomTypeList.map((item,index)=>(
                    <div key={item.room_type_id} className="col-lg-4 col-md-6 fadeInUp">
                        <div className="room-item shadow rounded overflow-hidden">
                            <img style={{maxHeight:"440px",width:"100%"}} src="img/room-1.jpg" alt=""/>
                            <div className="p-4 mt-2">
                                <div className="d-flex justify-content-between mb-3">
                                    <h5 className="">{item.room_type_name}</h5>
                                </div>
                                <div className="d-flex mb-3">
                                    <div><FontAwesomeIcon icon={faPerson} /> 可入住 {item.room_type_sleep} 人</div><hr className={"separator"}/>
                                    <div><FontAwesomeIcon icon={faBed} /> 1 張標準雙人床</div>
                                </div>
                                <div style={{textAlign:"right"}}>入住 {item.night} 晚，總共</div>
                                <div style={{textAlign:"right"}} className={"h3"}>NTD {item.room_total_price}</div>
                                <div className="d-flex justify-content-between">
                                    <Button
                                        id={item.room_type_id}
                                        className={"col-5 btn btn-sm rounded detailBtn roomTypeListDetailBtn"}
                                        onClick={(e)=>props.detail(item.room_type_id,1,item.night,item.room_total_price,props.checkInDate,props.checkOutDate)}
                                    ><div style={{display:"none"}}>{item.room_type_id}</div>詳情</Button>
                                    <Button
                                        id={item.room_type_id}
                                        className={"col-5 btn btn-sm rounded mainBtn roomTypeListBookingBtn"}
                                        // onClick={booking_search}
                                        onClick={()=> {
                                            props.updateSearchType(999);
                                            props.bookIn(1, item.hotel_id,item.room_type_id,props.checkInDate,props.checkOutDate,item.room_type_name,item.room_type_sleep,item.room_total_price,item.night)
                                        }}
                                    >預訂</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default RoomTypeList;