import React from 'react';
import { Route,Routes,Link } from 'react-router-dom';
import Home from "./Components/Home/Home";
import Result from "./Components/Result/Result";
import OtherProductsList from "./Components/OtherProductsList/OtherProductsList";

import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';
import Pay from "./Components/Pay/Pay";
import Url from "./Url/Url";

const apiServer = Url;

const App = ()=> {

    return (

        <Container className={"bg-white p-0"} fluid="xxl">

            <Container className={"bg-dark px-0 header_area"} fluid>
                <Container fluid style={{maxWidth:"1400px"}}>
                    <div className="row gx-0">
                        <div className="col-lg-3 bg-dark d-lg-block">
                            <Link to={"/"}
                               className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center">
                                <h1 className={"m-0"} style={{color:"rgb(106 218 169)",padding:"15px"}}>遠悅飯店</h1>
                            </Link>
                        </div>
                    </div>
                </Container>
            </Container>


            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/home" element={<Home/>}/>
                <Route path="result" element={<Result/>} />
                <Route path="/pay/:id" element={<Pay/>} />
                <Route path="/otherProductsList/:hotel/:room" element={<OtherProductsList/>} />
            </Routes>

        </Container>
    );
}

export default App;
