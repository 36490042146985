import React from "react";
import {Button, Container, Modal} from 'react-bootstrap';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBed, faCheck, faMoon, faPerson} from "@fortawesome/free-solid-svg-icons";

const BookingDetailModal = props => {
    return (
        <Modal
            id={"detailModal"}
            size="xl"
            show={props.showDetailModal}
            onHide={()=>props.onChangeShowDetailModal(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{paddingTop:"0px"}}>
                {
                    props.roomTypeDetailData && <Container>
                        {
                            props.roomTypeDetailData.map((item,index1)=> (
                                <div key={index1}>
                                    <div key={index1} className={"row"}>
                                        <div className={"col-lg-7"}>
                                            <img src="https://fakeimg.pl/350x200/000/ccc?text=Photo" style={{width:"100%"}} className={"mb-3"} />
                                        </div>
                                        <div className={"col-lg-5"}>
                                            <h5 className="">{item.room_type_name}</h5>
                                            <div className="d-flex mb-3">
                                                <div><FontAwesomeIcon icon={faPerson} /> 可入住 {item.room_type_sleep} 人</div><hr className={"separator"}/>
                                                <div><FontAwesomeIcon icon={faBed} /> {item.bed} 張標準雙人床</div>
                                            </div>
                                            <div>入住時間：<br/>
                                                入住當天下午15:00過後 ~ 退房當天早上11:00之前
                                            </div>
                                            <div className={"row mt-1 mb-1"}>
                                                <div className={"col-6"}>
                                                    <div style={{backgroundColor: "#aad7ff",textAlign: "center",padding: "10px 0px"}}>
                                                        入住<br/>
                                                        {item.check_in_date}
                                                    </div>
                                                </div>
                                                <div className={"col-6"}>
                                                    <div style={{backgroundColor: "#fd9494",textAlign: "center",padding: "10px 0px"}}>
                                                        退房<br/>
                                                        {item.check_out_date}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{textAlign:"right"}}><FontAwesomeIcon icon={faMoon} /> 入住 {item.night} 晚，總共</div>
                                            <div style={{textAlign:"right"}} className={"h3"}>NTD {item.totalPrice}</div>
                                            <div style={{textAlign:"right"}}>
                                                <Button
                                                    className={"mainBtn"}
                                                    style={{width:"100%"}}
                                                    onClick={()=> {
                                                        props.onChangeShowDetailModal(false);
                                                        props.onBookIn(1, item.hotel_id,item.room_type_id,props.checkInDate, props.checkOutDate,item.room_type_name,item.room_type_sleep,item.room_total_price,item.night)
                                                    }}
                                                >預訂</Button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        item.room_type_introduce &&
                                        <div className={"row mt-3"}>
                                            <h5 className="">房型介紹</h5>
                                            <div>{item.room_type_introduce}</div>
                                        </div>
                                    }
                                    {
                                        item.room_type_device &&
                                        <div className={"row mt-3"}>
                                            <h5 className="">房內提供設備</h5>
                                            <div className="d-flex">
                                                {
                                                    item.room_type_device.map((device,imdex2)=>(
                                                        <div key={device.device_id} className={"device"}><FontAwesomeIcon icon={faCheck} /> {device.device_name}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }
                                    <hr className={"mt-4 mb-4"} style={{borderTop:"3px solid rgb(81 82 82)"}}/>
                                    <div className={"row"}>
                                        <div className={"col-lg-7"}>
                                            <img src="https://fakeimg.pl/350x200/000/ccc?text=Photo" style={{width:"100%"}} className={"mb-3"} />
                                        </div>
                                        <div className={"col-lg-5"}>
                                            <h5 className={"col-lg-5"}>{item.hotel_name}</h5>
                                            <span>{item.hotel_introduce}</span><br/>
                                            <span>地址：{item.hotel_address}</span><br/>
                                            <span>電話：{item.hotel_tel}</span>
                                        </div>
                                    </div>
                                    {
                                        item.hotel_device &&
                                        <div className={"row mt-3"}>
                                            <h5 className="">飯店提供設施</h5>
                                            {
                                                item.hotel_device.map((hotel_device,imdex3)=>(
                                                    <div key={hotel_device.hotel_device_id} className={"col-lg-3 col-md-4 col-6"}>
                                                        <div>{hotel_device.hotel_device_name}</div>
                                                        <ul key={hotel_device.hotel_device_id}>
                                                            {
                                                                hotel_device.hotel_device_sub.map((hotel_device_sub,index4)=>(
                                                                    <li key={hotel_device_sub.hotel_device_sub_id}>{hotel_device_sub.hotel_device_sub_name}</li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    }
                                    {
                                        item.not_allow &&
                                        <div className={"row mt-3"}>
                                            <h5 className="">禁止事項</h5>
                                            <div>
                                                {item.not_allow}
                                            </div>
                                        </div>
                                    }
                                    {
                                        item.traffic &&
                                        <div className={"row mt-3"}>
                                            <h5 className="">交通資訊</h5>
                                            <div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html:item.traffic}}>
                                            </div>
                                        </div>
                                    }
                                    <div className={"row mt-3"} style={{backgroundColor:"#eeeeee",borderRadius:"10px",padding:"20px 0px"}}>
                                        <div className={"col-lg-3"}>
                                            <h5 className="">{item.room_type_name}</h5>
                                            <div className="mb-3">
                                                <div><FontAwesomeIcon icon={faPerson} /> 可入住 {item.room_type_sleep} 人</div>
                                                <div><FontAwesomeIcon icon={faBed} /> {item.bed} 張標準雙人床</div>
                                            </div>
                                        </div>
                                        <div className={"col-lg-6"}>
                                            <div>入住時間：<br/>
                                                入住當天下午15:00過後 ~ 退房當天早上11:00之前
                                            </div>
                                            <div className={"row mt-1 mb-1"}>
                                                <div className={"col-6"}>
                                                    <div style={{backgroundColor: "#aad7ff",textAlign: "center",padding: "10px 0px"}}>
                                                        入住<br/>
                                                        {item.check_in_date}
                                                    </div>
                                                </div>
                                                <div className={"col-6"}>
                                                    <div style={{backgroundColor: "#fd9494",textAlign: "center",padding: "10px 0px"}}>
                                                        退房<br/>
                                                        {item.check_out_date}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col-lg-3"}>
                                            <div style={{textAlign:"right"}}><FontAwesomeIcon icon={faMoon} /> 入住 {item.night} 晚，總共</div>
                                            <div style={{textAlign:"right"}} className={"h3"}>NTD {item.totalPrice}</div>
                                            <div style={{textAlign:"right"}}>
                                                <Button
                                                    className={"mainBtn"}
                                                    style={{width:"100%"}}
                                                    onClick={()=> {
                                                        props.onChangeShowDetailModal(false);
                                                        props.onBookIn(1, item.hotel_id,item.room_type_id,props.checkInDate, props.checkOutDate,item.room_type_name,item.room_type_sleep,item.room_total_price,item.night)
                                                    }}
                                                >預訂</Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row mt-3"}>
                                        <div className={"col-12"} style={{textAlign:"center"}}>
                                            <Button
                                                className={"closeBtn"}
                                                style={{width:"4em"}}
                                                onClick={()=>props.onChangeShowDetailModal(false)}
                                            >關閉</Button>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </Container>
                }
            </Modal.Body>
        </Modal>
    );
}

export default BookingDetailModal;