import React, {useEffect, useRef, useState} from "react";
import { useParams } from 'react-router-dom';
import Spinner from "../Spinner/Spinner";
import Form from 'react-bootstrap/Form';
import axios from "axios";
import {Button, Container} from "react-bootstrap";
import Url from "../../Url/Url";

const apiServer = Url;

const OtherProductsList = props => {

    const [showSpinner,setShowSpinner] = useState(false);

    const {hotel,room} = useParams();
    const [otherPrd,setOtherPrd] = useState([]);

    let otherPrd_MIDRef = useRef(null);
    let otherPrd_VersionRef = useRef(null);
    let otherPrd_TradeInfoRef = useRef(null);
    let otherPrd_TradeShaRef = useRef(null);

    let prdArr = [];

    let totalPrice = ()=>{
        prdArr = [];
        let products = document.querySelectorAll('.otherPrd_checkbox');
        let prdLength = products.length;

        for (let i=0;i<prdLength;i++){
            if(products[i].checked == true){
                let arr = products[i].id.split('_');
                console.log(arr[1]);

                prdArr.push({
                    prdId:arr[1],
                    amount:document.getElementById(`select_${arr[1]}`).value,
                    price:document.getElementById(`select_${arr[1]}`).dataset.price
                })
            }
        }

        if(prdArr.length != 0){
            let totalPrice = 0;
            prdArr.forEach((item)=>{
                totalPrice += +item.price * +item.amount;
            });

            document.getElementById('total').innerText = numberComma(totalPrice);
            console.log(numberComma(totalPrice));
        };

    }

    let numberComma = (num)=>{
        let comma=/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g
        return num.toString().replace(comma, ',')
    }

    useEffect(()=>{
        axios.post(`${apiServer}/orders/loadOtherProductsList`,{
            itemId1:hotel,itemId2:room
        }).then((data) =>{
            if(data.status === 200){
                if(data.data.length != 0){
                    setOtherPrd([data.data[0]]);
                }else{
                }
            }else{
                console.log("Server Error")
            }
        });

        // axios.post(`${apiServer}/orders/otherPrd_checkout`,{
        //     itemId1:hotel,itemId2:room,itemId3:prdArr
        // }).then((data2) =>{
        //     if(data2.status === 200){
        //         if(data2.data.length != 0){
        //             // no = data2.data.No;
        //             // checkout_MIDRef.current.value = data2.data.MerchantID;
        //             // checkout_VersionRef.current.value = data2.data.Version;
        //             // checkout_TradeInfoRef.current.value = data2.data.TradeInfo;
        //             // checkout_TradeShaRef.current.value = data2.data.TradeSha;
        //         }else{
        //         }
        //     }else{
        //         console.log("Server Error")
        //     }
        // });
        // axios.post(`${apiServer}/orders/pay`,{
        //     itemId1:id
        // }).then((data) =>{
        //     if(data.status === 200){
        //         if(data.data.length != 0){
        //             if(data.data[0].result != 0){
        //                 payLink_MIDRef.current.value = data.data[0].result.MerchantID;
        //                 payLink_VersionRef.current.value = data.data[0].result.Version;
        //                 payLink_TradeInfoRef.current.value = data.data[0].result.TradeInfo;
        //                 payLink_TradeShaRef.current.value = data.data[0].result.TradeSha;
        //
        //                 // console.log(data.data);
        //
        //                 document.getElementById('payLinkForm').submit();
        //             }
        //         }else{
        //         }
        //     }else{
        //         console.log("Server Error")
        //     }
        // });
    },[]);
    return (
        <Container fluid style={{maxWidth:"800px",marginTop:"6em"}}>
            {
                showSpinner && <Spinner/>
            }
            <form  id={"otherPrdForm"} method={"post"} action={"https://ccore.newebpay.com/MPG/mpg_gateway"}>
                {
                    otherPrd.map((item,index)=>(
                        <div key={hotel}>
                            <div style={{textAlign:"center"}}>
                                <h3>
                                    遠悅飯店{item.hotelName}
                                </h3>
                                <h4 style={{color:"blue"}}>
                                    房號：202浴缸
                                </h4>
                                <span style={{color:"red"}}>請確認房號是否正確</span>
                            </div>

                            <div className={"row"}>
                                <div className={"table-responsive col-12 sameHotelTable"}>
                                    <table className={"table table-bordered mt-3"}>
                                        <thead>
                                        <tr className={"d-flex theHeader"}>
                                            <th scope="col" className={"col-5 col-md-4"} style={{textAlign:"center"}}>產品(請勾選)</th>
                                            <th scope="col" className={"col-3 col-md-4"} style={{textAlign:"center"}}>價格</th>
                                            <th scope="col" className={"col-4 col-md-4"} style={{textAlign:"center"}}>數量</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            item.otherPrdList.map((item2,index2)=>(
                                                <tr key={index2} className={"d-flex"}>
                                                    <td className={"col-5 col-md-4"}>
                                                        <div style={{display: "flex", alignItems: "center"}}>
                                                            <input id={`cb_${item2.otherPrd_id}`}
                                                                   className={"search otherPrd_checkbox"}
                                                                   type="checkbox"
                                                                   style={{width:"1.2em",height:"1.2em"}}
                                                                   onChange={(e)=>{
                                                                       if(e.target.checked){
                                                                           document.getElementById(`select_${item2.otherPrd_id}`).disabled = false;
                                                                       }else{
                                                                           document.getElementById(`select_${item2.otherPrd_id}`).disabled = true;
                                                                       }

                                                                       totalPrice();
                                                                   }}
                                                            />&nbsp;
                                                            <label>{item2.item_no}.{item2.otherPrd_name}</label>
                                                        </div>
                                                    </td>
                                                    <td className={"col-3 col-md-4"}>
                                                        {item2.otherPrd_price}元
                                                    </td>
                                                    <td className={"col-4 col-md-4"}>
                                                        <Form.Select
                                                            id={`select_${item2.otherPrd_id}`}
                                                            style={{width:"5em",padding: "0 2.25rem 0 .75rem"}}
                                                            className={"otherPrd_amount"}
                                                            disabled={true}
                                                            data-price={item2.otherPrd_price}
                                                            onChange={(e)=>{
                                                                // console.log(e.target.value,e.target.dataset.price)
                                                                //
                                                                // console.log(document.querySelectorAll('.otherPrd_checkbox').length);

                                                                totalPrice();

                                                            }}
                                                        >
                                                            {
                                                                item2.optionArray.map((item3,index3)=>(
                                                                    <option key={item3} value={item3}>{item3}</option>
                                                                ))
                                                            }
                                                        </Form.Select>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                        </div>
                    ))
                }

                <div className={"display_none"}>
                    MID: <input name="MerchantID" ref={otherPrd_MIDRef} readOnly />
                    Version: <input name="Version" ref={otherPrd_VersionRef} readOnly />
                    TradeInfo: <input name="TradeInfo" ref={otherPrd_TradeInfoRef} readOnly />
                    TradeSha: <input name="TradeSha" ref={otherPrd_TradeShaRef} readOnly />
                </div>

                <div style={{paddingTop:"10px",borderTop: "3px black solid"}}>
                    <div className={"row mb-3"} style={{fontSize:"1.2em"}}>
                        <div className={"col-6"} style={{textAlign:"left"}}>總計：</div>
                        <div className={"col-6"} style={{textAlign:"right"}}><span id={"total"}>0</span>&nbsp;元</div>
                    </div>

                    <Button
                        className={"col-5 btn btn-sm rounded mainBtn w-100 mb-4"}
                        onClick={(event)=>{
                            event.preventDefault();
                            setShowSpinner(true);
                            if(prdArr.length == 0){
                                alert("請勾選欲加購的產品及數量");
                            }else{
                                axios.post(`${apiServer}/orders/otherPrd_checkout`,{
                                    itemId1:hotel,itemId2:room,itemId3:prdArr
                                }).then((data) =>{
                                    if(data.status === 200){
                                        if(data.data.length != 0){
                                            console.log(data.data);
                                            switch (data.data.result){
                                                case 0:
                                                    alert(data.data.message);
                                                    setShowSpinner(false);
                                                    break;
                                                case 1:
                                                    otherPrd_MIDRef.current.value = data.data.message.MerchantID;
                                                    otherPrd_VersionRef.current.value = data.data.message.Version;
                                                    otherPrd_TradeInfoRef.current.value = data.data.message.TradeInfo;
                                                    otherPrd_TradeShaRef.current.value = data.data.message.TradeSha;
                                                    console.log(otherPrd_MIDRef.current.value,otherPrd_VersionRef.current.value,otherPrd_TradeInfoRef.current.value,otherPrd_TradeShaRef.current.value);

                                                    setTimeout(()=>{
                                                        document.getElementById('otherPrdForm').submit();
                                                    },2000);
                                                    break;
                                            }
                                        }else{

                                        }
                                    }else{
                                        console.log("Server Error")
                                    }
                                });
                            }
                        }}
                        type={"submit"}
                    >結帳</Button>
                </div>
            </form>
        </Container>
    );
}

export default OtherProductsList;