import React from "react";
import {Button, Modal} from "react-bootstrap";

const ProjectDetailModal = props => {
    return (
        <Modal
            id={"projectDetailModal"}
            size="lg"
            show={props.showProjectDetailModal}
            onHide={()=>props.onChangeShowProjectDetailModal(false)}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body style={{paddingTop:"0px"}}>
                {
                    props.projectDetailData && props.projectDetailData.map((item,index)=>(
                        <div key={item.project_id}>
                            <h3 style={{borderBottom: "5px solid black"}}>{item.project_name}</h3>
                            <div style={{fontWeight:"800",fontSize:"1.1em"}}>
                                <div className={"mb-3"}>專案販售期間：{item.project_saleDate}</div>
                                <div className={"mb-3"}>專案可使用期間：{item.project_useDate}</div>
                                <div className={"mb-3"}>專案價：NTD {item.project_price}</div>
                                <div>專案介紹：</div>
                                <div className={"mb-3"}>{item.project_introduce}</div>
                                <div>專案規格：</div>
                                <div className={"mb-3"}>{item.project_content.slice(0,-1)}</div>
                                <div>可使用館別：</div>
                                <div className={"mb-3"}>{item.project_hotel.slice(0,-1)}</div>
                                <div>不適用日期：</div>
                                <div style={{color:"red"}} className={"mb-3"}>{item.nonDate.slice(0,-1)}</div>
                                <div>專案規則：</div>
                                <div style={{lineHeight:"25px"}}>{item.project_reg}</div>
                            </div>
                            <hr className={"mt-4 mb-4"} style={{borderTop:"3px solid rgb(81 82 82)"}}/>
                            <div className={"row"}>
                                <div className={"col-lg-7"}>
                                    <img src="https://fakeimg.pl/350x200/000/ccc?text=Photo" style={{width:"100%"}} className={"mb-3"} />
                                </div>
                                <div className={"col-lg-5"}>
                                    <h5 className={"col-lg-5"}>{item.hotel_name}</h5>
                                    <span>{item.hotel_introduce}</span><br/>
                                    <span>地址：{item.hotel_address}</span><br/>
                                    <span>電話：{item.hotel_tel}</span>
                                </div>
                            </div>
                            {
                                item.hotel_device &&
                                <div className={"row mt-3"}>
                                    <h5 className="">飯店提供設施</h5>
                                    {
                                        item.hotel_device.map((hotel_device,imdex3)=>(
                                            <div key={hotel_device.hotel_device_id} className={"col-lg-3 col-md-4 col-6"}>
                                                <div>{hotel_device.hotel_device_name}</div>
                                                <ul key={hotel_device.hotel_device_id}>
                                                    {
                                                        hotel_device.hotel_device_sub.map((hotel_device_sub,index4)=>(
                                                            <li key={hotel_device_sub.hotel_device_sub_id}>{hotel_device_sub.hotel_device_sub_name}</li>
                                                        ))
                                                    }
                                                </ul>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                item.not_allow &&
                                <div className={"row mt-3"}>
                                    <h5 className="">禁止事項</h5>
                                    <div>
                                        {item.not_allow}
                                    </div>
                                </div>
                            }
                            {
                                item.traffic &&
                                <div className={"row mt-3"}>
                                    <h5 className="">交通資訊</h5>
                                    <div style={{whiteSpace: "pre-line"}} dangerouslySetInnerHTML={{__html:item.traffic}}>
                                    </div>
                                </div>
                            }
                            <div className={"mt-4 mb-3"} style={{textAlign:"center"}}>
                                <Button
                                    className={"closeBtn"}
                                    style={{width:"7em",margin:"0px 5px"}}
                                    onClick={()=>props.onChangeShowProjectDetailModal(false)}
                                >關閉</Button>
                                <Button
                                    id={item.project_id}
                                    style={{width:"7em",margin:"0px 5px"}}
                                    className={"mainBtn"}
                                >查詢空房</Button>
                            </div>
                        </div>
                    ))
                }
            </Modal.Body>
        </Modal>
    );
}

export default ProjectDetailModal;