import React from "react";
import {Button} from "react-bootstrap";

const ProjectList = props => {
    return (
        <div>
            {
                props.projectList.map((item,index)=>(
                    <div key={item.project_id} className={"row g-4 mb-3"}>
                        <div className="col-12 fadeInUp">
                            <div className="room-item shadow rounded overflow-hidden">
                                <div className="p-4">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className={"mb-3"}>
                                                <h5 style={{display:"inline-block"}}>{item.project_name}</h5>
                                                {
                                                    item.project_hotel.map((item,index)=>(
                                                        <div key={item.hotel_id} className={"project-hotel"}>{item.hotel_name}</div>
                                                    ))
                                                }
                                            </div>
                                            <div className={"mb-3"}>
                                                <div style={{display:"inline-block"}}>專案價</div>
                                                <div style={{display:"inline-block",margin:"0px"}} className={"h3"}>NTD {item.project_price}</div>
                                            </div>
                                            <div className={"mb-3"}>{item.project_content.slice(0,-1)}</div>
                                            <div className={"mb-3"}>{item.project_introduce}</div>
                                            <div>
                                                <div style={{display:"inline-block"}}>專案販售期間：{item.project_saleDate}</div>&nbsp;&nbsp;&nbsp;&nbsp;
                                                <div style={{display:"inline-block"}}>專案使用期間：{item.project_useDate}</div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex",flexDirection: "column",alignItems: "flex-start",justifyContent: "space-between",width:"150px"}}>
                                            <div>
                                                <Button
                                                    id={item.project_id}
                                                    style={{width:"150px"}}
                                                    className={"btn btn-sm rounded mainBtn"}
                                                    onClick={()=>props.onLoadAvailableCalendar(item.project_id,props.checkInDate,item.hotel_id)}
                                                >查詢空房</Button>
                                            </div>
                                            <div>
                                                <Button
                                                    id={item.project_id}
                                                    style={{width:"150px"}}
                                                    className={"btn btn-sm rounded detailBtn"}
                                                    onClick={(e)=>props.project_detail(item.project_id)}
                                                >專案詳情</Button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default ProjectList;